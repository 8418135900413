import React, { Suspense, useState } from 'react'
import { Canvas } from 'react-three-fiber'
import Herakles from '../components/Herakles'

const Intro = ({}) => {
  const [clientY, setClientY] = useState(null)
  const [clientX, setClientX] = useState(null)
  return (
    <div
      onMouseMove={e => {
        setClientY(e.clientY)
        setClientX(e.clientX)
      }}
    >
      <div className='intro'>
        <Canvas style={{ height: '900px' }}>
          <ambientLight intensity={0.1} />
          <Suspense fallback={null}>
            <Herakles positionY={clientY} positionX={clientX} />
          </Suspense>
        </Canvas>
        <div className='introCopy'>
          <h1>
            WE CREATE WEBSITES THAT CONNECT <span>BRANDS</span> WITH THEIR{' '}
            <span>AUDIANCE</span>
          </h1>
        </div>
      </div>
    </div>
  )
}
export default Intro
