import React from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import './index.css'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { Blob } from 'react-blob'
import { Grid, Row, Col } from 'react-flexbox-grid'
import Footer from '../components/Footer'
import { FaLongArrowAltRight } from 'react-icons/fa'

const IndexPage = () => {
  return (
    <Layout>
      <Intro />
      <div className='info'>
        <Blob
          size='100vh'
          style={{
            position: 'absolute',
            top: '250px',
            right: '-10%',
            zIndex: -1,
            backgroundColor: '#000',
            opacity: '0.6'
          }}
        />
        <Blob
          size='35vh'
          style={{
            position: 'absolute',
            top: '650px',
            left: '-10%',
            zIndex: -1,
            backgroundColor: '#e84545',
            opacity: '1'
          }}
        />
        <Row>
          <Col xs={12}>
            <div className='line'></div>
          </Col>
        </Row>
        <Row className='whiteRow'>
          <Col xs={12} sm={4} smOffset={4} className='center'>
            <h2 className='topSpacerSmall'>About us</h2>
            <p>
              Needs copy Together, we build not only products that function, but
              products that deliver, attract, and engage with people.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className='line'></div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='center topSpacer'>
            <h2>Our services</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <div className='textBlock'>
              <h3>Website Development</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className='textBlock'>
              <h3>User Experience Strategy</h3>
              <p>
                Our extensive user experience strategy allows you to have the
                comfort of mind knowing all decisions are backed up by
                quantitative data and user feedback.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className='textBlock'>
              <h3>Visual Design</h3>
              <p>
                Our full bodied design process will bring your business to life,
                leaving you with a effective and high performing wireframe that
                will elevate your business.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <div className='textBlock'>
              <h3>Website Development</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam
              </p>
            </div>
          </Col>
        </Row>
        <Grid className='topSpacer'>
          <Row>
            <Col sm={12} md={6}>
              <h3>Our work</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </p>
              <AniLink
                to='work'
                cover
                direction='top'
                duration={1}
                bg='#E84545'
                className='link'
              >
                Check it out <FaLongArrowAltRight />
              </AniLink>
            </Col>
            <Col sm={12} md={6}>
              <Blob
                size='35vh'
                style={{
                  position: 'relative',
                  zIndex: -1,
                  backgroundColor: '#e84545',
                  opacity: '1',
                  margin: '0 auto'
                }}
              />
            </Col>
          </Row>
        </Grid>
        <Grid className='topSpacer'>
          <Row>
            <Col sm={12} md={6}>
              <Blob
                size='35vh'
                style={{
                  position: 'relative',
                  zIndex: -1,
                  backgroundColor: '#e84545',
                  opacity: '1',
                  margin: '0 auto'
                }}
              />
            </Col>
            <Col sm={12} md={6}>
              <h3>Our process</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam,
              </p>
              <AniLink
                to='work'
                cover
                direction='top'
                duration={1}
                bg='#E84545'
                className='link'
              >
                Check it out <FaLongArrowAltRight />
              </AniLink>
            </Col>
          </Row>
        </Grid>
      </div>
      <Footer />
    </Layout>
  )
}

export default IndexPage
