import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Herakles (props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/scene.gltf')
  const { positionX, positionY } = props
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group
          position={[0, 0, 0.03]}
          rotation={[(positionY/2500)+50, -0.07, positionX/1500]}
          scale={[0.7, 0.7, 0.7]}
        >
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_0.geometry}
          />
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_1.geometry}
          />
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_2.geometry}
          />
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_3.geometry}
          />
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_4.geometry}
          />
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_5.geometry}
          />
          <mesh
            material={materials['herakles-100k_Material_u1_v1.001']}
            geometry={nodes.mesh_6.geometry}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/scene.gltf')
