import React, { useState } from 'react'
import './footer.css'
import { Grid, Row, Col } from 'react-flexbox-grid'
import VisibilitySensor from 'react-visibility-sensor'

const Footer = () => {
  const [colour, setColour] = useState('#fff')

  function onChange (isVisible) {
    if (isVisible) {
      document.body.style.backgroundColor = '#e84545'
      setColour('#000')
    } else {
      document.body.style.backgroundColor = '#0f0f0f'
      setColour('#fff')
    }
  }

  return (
    <VisibilitySensor onChange={onChange}>
      <footer>
        <Grid>
          <Row>
            <Col sm={12}>
              <div className='cta'>
                <span>Let's talk</span>
                <a href='mailto:hello@ruptix.com' style={{color: colour}}>hello@ruptix.com</a>
              </div>
            </Col>
          </Row>
        </Grid>
        <div className='subFooter'>
          <Grid>
            <Row>
              <Col sm={12}>
                <div className='copyrightBar'>
                  <div>© {new Date().getFullYear()} Ruptix</div>
                </div>
              </Col>
            </Row>
          </Grid>
        </div>
      </footer>
    </VisibilitySensor>
  )
}

export default Footer
